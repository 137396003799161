export const ErrorMessages = Object.freeze({
  ERR0000: "Something went wrong",
  IN0001: "Error such an integration already exists",
  IN0002: "Something went wrong, the integration to update was not found",
  US0001: "The instance stack is already in its latest version",
  US0002: "There is a stack update already running",
  US0004: "The instance stack cannot be updated",
  CS0001: "The support access is being enabled",
  CS0002: "The support access is already enabled",
  CS0003: "There is no support access enabled for the instance account",
  CS0004: "The support access for the instance account is not fully enabled",
  US0003: "The instance stack have an incorrect status and cannot be updated",
  QR0001: "The connect backup version {0} doesn't exists",
  QR0002: "There is a connect backup restore already running",
  QR0003: "There is a sync for users already running, please wait untill it finishes",
  QR0004: "There is a delete process for a user already running, please wait until it finishes",
  QR0005: "The transfer user copy process is already running, please wait untill it finishes",
  CH0001: "Attached files could not be uploaded",
  CH0002: "There is an error with SMPT server configuration",
  PC0001: "Error creating or updating the survey",
  PC0002: "Error fetching list of surveys",
  PC0003: "Error deleting the surveys",
  QC0001: "User Deletion has been failed as user is being used as quick connect",

  SI0001: "The custom stack integration id is required",
  SI0002: "The custom stack integration definition with given id doesn't exists",
  SI0003: "The custom stack integration deploy is already in latest version",
  SI0004: "The custom stack integration deploy with given id doesn't exists",
  SI0005: "The custom stack integration deploy status is not deployed",
  SI0006: "The custom stack is already deployed in your account, please remove it first",
  SI0007: "The custom stack deployed is missing in your account",
  SI0008: "The value for the template parameter '{0}' is missing",
  SI0009: "The value for the template parameter '{0}' is invalid",
  SI0010: "The integration is not deployed.",
  S30001: "Provided s3 bucket doesn't have write permissions",
  SI0011: "The meeting is full.",
  SI0012: "Bucket and Key must be provided.",
  SI0013: "Method for S3 presigned request not found.",
  SI0014: "The value given for AE/CTR Stream is not correct",
  SI0015: "The given value for AE/CTR Stream is same",

  AI0001: "Your account is not authorized to invoke this operation.",
  AI0002: "Provided model not found.",
  AI0003: "Region not found for the model.",
  AI0004: "Additional configuration is required, please refer to the documentation: https://docs.dextr.cloud/smartscripts",
  AI0005: "Provided model is not ready to be used.",
  AI0006: "Question or sentence missed.",
  AI0007: "Model returned a malformed response. Try to adjust the max tokens or the temperature from the Model settings.",
  AI0008: "The provided model identifier is invalid.",
  CB0001: "A backup has already been running for this instance, please wait it to get finished first",
  AI0009: "Please make sure your instaceIdAlias or securityProfile",
  QS0001: "Data Lake Card is a Pre-requisite for Quicksight Deployment. Athena views are missing.",

  CRR0001: "This stack depends on diasester recovery, Please deploy disaster recovery and then deploy cross region resources",
  IN0004: "Integration is being deleted. Please wait for the process to complete.",
  SP0001: "Cannot delete the default security profile.",
});
